import React from "react";
import "./styles.css";

class Share extends React.Component {
  render() {
    return <div className="share">This is the share page</div>;
  }
}

export default Share;
